import React from 'react';

import SolutionsUseCases from 'components/pages/solutions/solutions-use-cases/solutions-use-cases';
import RequestDemoHero from 'components/shared/request-demo-hero';
import MainLayout from 'layouts/main';
import SeoMetadata from 'utils/seo-metadata';

const pageTitle = 'Solutions';
const pageDescription = 'Leverage Yobi’s Technology to unlock revenue and grow your business';

const Intelligence = () => (
  <MainLayout pageMetadata={SeoMetadata.solutions}>
    <RequestDemoHero title={pageTitle} description={pageDescription} />
    <SolutionsUseCases />
  </MainLayout>
);

export default Intelligence;
