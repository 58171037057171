import React from 'react';

import CardWithIcon from 'components/shared/card-with-icon';
import Container from 'components/shared/container';

import DataMonetizationIcon from './images/data-monetization-icon.inline.svg';
import DataScienceIcon from './images/data-science-icon.inline.svg';
import FinanceIcon from './images/finance-icon.inline.svg';
import MarketingIcon from './images/marketing.inline.svg';

const items = [
  {
    icon: MarketingIcon,
    title: 'Marketing / Advertising',
    description: 'Match all first party data or CRM to Yobi’s Behavioral Graph',
    linkText: 'Learn more',
    linkUrl: '/marketing-advertising',
  },
  {
    icon: DataScienceIcon,
    title: 'Data Science',
    description:
      'Identify the connections between consumer behavior and market performance to improve investment strategy',
    linkText: 'Learn more',
    linkUrl: '/data-science',
  },
  {
    icon: DataMonetizationIcon,
    title: 'Data Monetization',
    description:
      'Identify the connections between consumer behavior and market performance to improve investment strategy',
    linkText: 'Learn more',
    linkUrl: '/data-monetization',
  },
  {
    icon: FinanceIcon,
    title: 'Finance',
    description:
      'Identify the connections between consumer behavior and market performance to improve investment strategy',
    linkText: 'Learn more',
    linkUrl: '/finance',
  },
];

const SolutionsUseCases = () => (
  <section className="pt-24 lg:pt-32">
    <Container>
      <div className="grid grid-cols-1 gap-8 md:grid-cols-2 xl:grid-cols-4">
        {items.map(({ icon: Icon, title, description, linkText, linkUrl }, index) => (
          <CardWithIcon
            title={title}
            description={description}
            linkUrl={linkUrl}
            linkText={linkText}
            icon={Icon}
            theme="white"
            linkTheme="primary-2"
            key={index}
          />
        ))}
      </div>
    </Container>
  </section>
);

export default SolutionsUseCases;
